export const PSectionAgencies = "agencies";
export const PSectionUniversities = "universities";
export const PSectionStudents = "students";
export const PSectionAnnouncements = "announcements";
export const PSectionSuppliers = "suppliers";
export const PSectionApplications = "applications";
export const PSectionAgreement = "agreement";
export const PSectionAgreementProfits = "agreement-profits";
export const PSectionReleasedProfits = "released-profits";
export const PSectionSpecializations = "specializations";
export const PSectionRoles = "roles";
export const PSectionUsers = "users";
export const PSectionExpenses = "expenses";
export const PSectionMessageRequests = "message-requests";
export const PSectionWithdrawals = "withdrawals";
export const PSectionQuestions = "questions";

export const PAgenciesCreate = "create";
export const PAgenciesModify = "modify";
export const PAgenciesDelete = "delete";
export const PAgenciesViewAll = "view-all";
export const PAgenciesDeactivate = "deactivate";
export const PAgenciesSendByWhatsApp = "send-by-whatsapp";
export const PAgenciesChangeCommissions = "change-commissions";

export const PStudentsCreate = "create";
export const PStudentsModify = "modify";
export const PStudentsDelete = "delete";
export const PStudentsViewAll = "view-all";
export const PStudentsForDropdown = "for-dropdown";

export const PUniversitiesCreate = "create";
export const PUniversitiesModify = "modify";
export const PUniversitiesDelete = "delete";
export const PUniversitiesViewAll = "view-all";
export const PUniversitiesView = "view";
export const PUniversitiesDeactivate = "deactivate";
export const PUniversitiesForDropdown = "for-dropdown";

export const PSpecializationsCreate = "create";
export const PSpecializationsModify = "modify";
export const PSpecializationsDelete = "delete";
export const PSpecializationsViewAll = "view-all";
export const PSpecializationsForDropdown = "for-dropdown";
export const PSpecializationsForApplications = "for-applications";
export const PSpecializationsClose = "close";
export const PSpecializationsCalculateProfit = "calculate-profit";

export const PSuppliersCreate = "create";
export const PSuppliersModify = "modify";
export const PSuppliersDelete = "delete";
export const PSuppliersViewAll = "view-all";
export const PSUppliersDeactivate = "deactivate";

export const PApplicationsCreate = "create";
export const PApplicationsModify = "modify";
export const PApplicationsDelete = "delete";
export const PApplicationsViewAll = "view-all";
export const PApplicationsChangeStatus = "change-status";
export const PApplicationsView = "view";
export const PApplicationsChangeSupplier = "change-supplier";
export const PApplicationsChangeRepresentative = "change-representative";
export const PApplicationsViewDeadlines = "view-deadlines-page";
export const PApplicationsSetDeadline = "set-deadline";
export const PApplicationsReGenerateProfits = "regenerate-profits";
export const PApplicationsClaimProfit = "claim-profit";
export const PApplicationsClaimAgentProfit = "claim-agent-profit";
export const PApplicationsShowProfit = "show-profit";

export const PAnnouncementsCreate = "create";
export const PAnnouncementsModify = "modify";
export const PAnnouncementsDelete = "delete";
export const PAnnouncementsViewAll = "view-all";

export const PAgreementView = "view";

export const PAgreementProfitsCreate = "create";
export const PAgreementProfitsModify = "modify";
export const PAgreementProfitsDelete = "delete";
export const PAgreementProfitsRelease = "release";
export const PAgreementProfitsViewAll = "view-all";
export const PAgreementProfitsGenerate = "generate";

export const PReleasedProfitsCreate = "create";
export const PReleasedProfitsModify = "modify";
export const PReleasedProfitsDelete = "delete";
export const PReleasedProfitsViewAll = "view-all";
export const PReleasedProfitsViewWithdrawals = "view-withdrawals";
export const PReleasedProfitsCreateWithdrawal = "create-withdrawal";
export const PReleasedProfitsReverseWithdrawal = "reverse-withdrawal";

export const PUsersCreate = "create";
export const PUsersModify = "modify";
export const PUsersDelete = "delete";
export const PUsersViewAll = "view-all";
export const PUsersDeactivate = "deactivate";

export const PRolesCreate = "create";
export const PRolesModify = "modify";
export const PRolesDelete = "delete";
export const PRolesViewAll = "view-all";

export const PExpensesCreate = "create";
export const PExpensesModify = "modify";
export const PExpensesDelete = "delete";
export const PExpensesViewAll = "view-all";

export const PMessageRequestsCreate = "create";
export const PMessageRequestsViewAll = "view-all";

export const PQuestionsViewAll = "view-all";
export const PQuestionsCreate = "create";
export const PQuestionsAnswer = "answer";
export const PQuestionsDelete = "delete";
