import React, { useState } from "react";
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { Form } from "reactstrap";

export const AgenciesBar = ({
  section,
  setParams,
  lang,
  activeSection = 1,
  currentAgencyId = null,
  total = 0,
}) => {
  const currentAgencyTitle = currentAgencyId === 1 ? "ITQAN " : "Agency ";
  const [listAgencies, setListAgencies] = useState([
    {
      id: 1,
      name: window.sys_app_translate("Sub Agents " + section, lang),
    },
    {
      id: 2,
      name: window.sys_app_translate(currentAgencyTitle + section, lang),
    },
  ]);
  const [selectedAgenciesList, setSelectedAgenciesList] =
    useState(activeSection);
  return (
    <div className="row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row selection-top-bar">
            <div className="col-12 d-flex align-items-center justify-content-center">
              {listAgencies &&
                listAgencies.map((item, key) => {
                  return (
                    <div
                      className={`col-4 ${
                        item.id === 1 ? "mx-1" : ""
                      } col-sm-2 clickable mx-1 inter inter-bold d-flex align-items-center justify-content-center selection-top-bar-button ${
                        selectedAgenciesList === item.id ? "active" : ""
                      }`}
                      key={`uc${key}`}
                      style={{ position: "relative" }}
                      onClick={() => {
                        setSelectedAgenciesList(item.id);
                        setParams((prevState) => ({
                          ...prevState,
                          onlycurrentagency: item.id === 2 ? true : false,
                          pagenumber: 1,
                        }));
                      }}
                    >
                      {window.sys_app_translate(item.name, lang)}
                      {selectedAgenciesList === item.id && (
                        <div className="button-badge mx-2">{total}</div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UniversitiesBar = ({ setParams, lang, activeSection = 1 }) => {
  const [listUniversities, setListUniversities] = useState([
    {
      id: 1,
      name: window.sys_app_translate("Türkiye Universities", lang),
    },
    {
      id: 2,
      name: window.sys_app_translate("Cyprus Universities", lang),
    },
  ]);
  const [selectedUniversitiesList, setSelectedUniversitiesList] =
    useState(activeSection);
  return (
    <div className="row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row selection-top-bar">
            <div className="col-12 d-flex align-items-center justify-content-center">
              {listUniversities &&
                listUniversities.map((item, key) => {
                  return (
                    <div
                      className={`col-4 ${
                        item.id === 1 ? "mx-1" : ""
                      } col-sm-2 clickable mx-1 inter inter-bold d-flex align-items-center justify-content-center selection-top-bar-button text-bold ${
                        selectedUniversitiesList === item.id ? "active" : ""
                      }`}
                      key={`uc${key}`}
                      onClick={() => {
                        setSelectedUniversitiesList(item.id);
                        setParams(item.id);
                      }}
                    >
                      {window.sys_app_translate(item.name, lang)}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
